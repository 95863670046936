export function getPicture(picture) {
    let url = process.env.REACT_APP_BASE_URL;

    if (url === "http://localhost:8000" || "http://localhost:8001") {
        url = "https://api.vendemmia.com.br";
    }

    const newImage = window.localStorage.getItem("temp-profile-picture");
    const tempPicture = newImage || picture;
    return (url + tempPicture)?.replace("//profile", "/profile");
}
