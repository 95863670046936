import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { FaTrashAlt, FaEdit, FaSave, FaTimesCircle } from "react-icons/fa";
import { Formik, ErrorMessage, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import Select from "react-select";

import requests from "../../../../../services/requests";
import profile from "../../../../../services/profile";
import { getTimeDistance } from "../../../../../assets/utils/date";
import { StageIcons } from "../../../../../components/Icons";

import { Container, FlexDiv } from "./styles";
import SimpleCollapse from "../../../../../components/Collapse/SimpleCollapse";
import { getPicture } from "../../../../../assets/utils/picture";
import ModalRemoveComment from "../../ModalRemoveComment";
import { toast } from "react-toastify";

function Comments({ process, actionComments, setActionComments }) {
    const [loadComments, setLoadComments] = useState(false);
    const [comments, setComments] = useState([]);
    const [commentSearchString, setCommentSearchString] = useState("");
    const [commentCancelTokenSource, setCommentCancelTokenSource] = useState(null);
    const [isEditting, setIsEditting] = useState(false);
    const [loadEditted, setLoadEditted] = useState(false);
    const [sortColumn, setSortColumn] = useState("new");

    const options = [
        { value: "new", label: "Mais novo" },
        { value: "last", label: "Mais antigo" },
    ];

    const loadList = () => {
        setLoadComments(true);

        if (commentCancelTokenSource) {
            commentCancelTokenSource.cancel();
        }

        setCommentCancelTokenSource(axios.CancelToken.source());

        const filters = {
            search: commentSearchString.length < 3 ? "" : commentSearchString,
            sortProcessComment: sortColumn,
        };

        requests.getProcessComments(process.identifier, filters, commentCancelTokenSource).then(data => {
            setLoadComments(false);
            setComments(data.data);
            setCommentCancelTokenSource(null);
            setLoadEditted(true);
        });
    };

    const onChangeCommentSearch = e => {
        const search = e.target.value.trim();
        if (search.length > 2 || commentSearchString.length >= e.target.value.trim().length) {
            setCommentSearchString(search);
        }
    };

    // Modal remove
    const [currentEntity, setCurrentEntity] = useState(null);
    const [openModalRemove, setOpenModalRemove] = useState(false);
    const actionModalRemove = entity => {
        setCurrentEntity(entity);
        setOpenModalRemove(!openModalRemove);
    };
    const closeModalRemove = () => {
        setOpenModalRemove(false);
    };

    const enableEditComment = entity => {
        setLoadEditted(false);

        document.getElementById("btn_edit_" + entity.identifier).style.display = "none";
        document.getElementById("btn_remove_" + entity.identifier).style.display = "none";
        document.getElementById("btn_save_" + entity.identifier).style.display = "inherit";
        document.getElementById("btn_cancel_" + entity.identifier).style.display = "inherit";

        document.getElementById("field_" + entity.identifier).readOnly = false;
        document.getElementById("field_" + entity.identifier).value = entity.description;
        //document.getElementById("field_"+entity.identifier).focus();
    };

    const cancelComment = entity => {
        document.getElementById("btn_edit_" + entity.identifier).style.display = "inherit";
        document.getElementById("btn_remove_" + entity.identifier).style.display = "inherit";
        document.getElementById("btn_save_" + entity.identifier).style.display = "none";
        document.getElementById("btn_cancel_" + entity.identifier).style.display = "none";

        setLoadEditted(true);
    };

    const saveComment = entity => {
        setIsEditting(true);
        const comment = document.getElementById("field_" + entity.identifier).value;

        requests
            .editProcessComment(entity.processIdentifier, entity.identifier, {
                description: comment,
                hasBeenRead: 0,
            })
            .then(response => {
                toast.success("Comentário alterado com sucesso!");

                if (response?.stage_id) {
                    setActionComments(!actionComments);
                } else {
                    setIsEditting(false);
                    setLoadEditted(true);
                    loadList();
                }
            })
            .catch(() => {
                toast.error("Problema ao salvar o comentário.");
            });
    };

    useEffect(() => {
        if (process && process.identifier) {
            loadList();
        }
    }, [commentSearchString, process, actionComments, sortColumn]);

    return (
        <div className="w-full lg:w-2/5 mt-12 lg:mt-0 pl-4 flex justify-between flex-col max-h-screen">
            <ModalRemoveComment
                actionModal={closeModalRemove}
                openModal={openModalRemove}
                entity={currentEntity}
                handleSuccess={() => {
                    closeModalRemove();
                    setActionComments(!actionComments);
                }}
            />
            <div className="flex justify-between">
                <h5 className="mb-4 mr-4">COMENTÁRIOS</h5>
                <input
                    className="px-2 h-8 text-left rounded-md border border-C2 w-full outline-none"
                    name="search"
                    placeholder="busca"
                    onChange={onChangeCommentSearch}
                />
                <div className="flex w-64">
                    <label htmlFor="sortBy" className="ml-8 mt-2 w-20">
                        Ordenar por
                    </label>
                    <Select
                        options={options}
                        id="sortBy"
                        name="sortBy"
                        value={options.filter(option => option.value === sortColumn)}
                        className="w-40 outline-none transform-none"
                        onChange={item => setSortColumn(item?.value)}
                    />
                </div>
            </div>
            <div className="overflow-y-auto h-full">
                {loadComments ? (
                    <div className="flex flex-col items-center">
                        <ClipLoader size={20} loading={loadComments} />
                    </div>
                ) : (
                    <>
                        {comments &&
                            comments.length > 0 &&
                            comments.map(entity => {
                                return (
                                    <Container key={entity.identifier} origin={process.returnOrigin}>
                                        <SimpleCollapse
                                            open={true}
                                            visible={
                                                <>
                                                    <img
                                                        src={getPicture(entity.userProfilePictureUri)}
                                                        alt={entity.userName}
                                                        onError={e => {
                                                            let tempPicture = e.target.src;
                                                            if (tempPicture.indexOf("http://localhost:8001") >= 0) {
                                                                tempPicture = tempPicture.replace(
                                                                    "http://localhost:8001",
                                                                    "https://api.vendemmia.com.br"
                                                                );
                                                            } else if (tempPicture.indexOf("http://localhost:8000") >= 0) {
                                                                tempPicture = tempPicture.replace(
                                                                    "http://localhost:8000",
                                                                    "https://api.vendemmia.com.br"
                                                                );
                                                            }

                                                            if (tempPicture !== e.target.src) {
                                                                e.target.src = tempPicture;
                                                            }

                                                            window.localStorage.removeItem("temp-profile-picture");
                                                        }}
                                                    />
                                                    <h6>{entity.userName}</h6>
                                                    <small className="font-normal text-C4 ">
                                                        {entity.isEdited
                                                            ? "(Editado) " + getTimeDistance(entity.updatedAt, 1)
                                                            : getTimeDistance(entity.createdAt, 1)}
                                                    </small>
                                                </>
                                            }
                                            invisible={
                                                <>
                                                    <aside>
                                                        {entity.stage && (
                                                            <>
                                                                <div id="line"></div>
                                                                <div className="icon">
                                                                    <StageIcons
                                                                        stage={entity.stage?.description}
                                                                        modality={entity.process?.transportModality}
                                                                        className="text-white fill fill-current w-5 h-3"
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </aside>

                                                    <span>
                                                        <textarea
                                                            id={"field_" + entity.identifier}
                                                            name={"field_" + entity.identifier}
                                                            className={loadEditted ? "txtComments" : ""}
                                                            readOnly
                                                        >
                                                            {entity.description}
                                                        </textarea>

                                                        {loadEditted && (
                                                            <span
                                                                id={"span_" + entity.identifier}
                                                                name={"span_" + entity.identifier}
                                                            >
                                                                {entity.description}
                                                            </span>
                                                        )}
                                                    </span>
                                                    {profile.identifier === entity.userIdentifier && !process.hideProcess && (
                                                        <div>
                                                            <button
                                                                id={"btn_save_" + entity.identifier}
                                                                className="buttonSave"
                                                                title="Salvar alteração"
                                                                disabled={isEditting}
                                                                onClick={() => saveComment(entity)}
                                                            >
                                                                <FaSave size={12} color="#fff" />
                                                            </button>
                                                            <button
                                                                id={"btn_cancel_" + entity.identifier}
                                                                className="buttonCancel"
                                                                title="Cancelar alteração"
                                                                onClick={() => cancelComment(entity)}
                                                            >
                                                                <FaTimesCircle size={12} color="#fff" />
                                                            </button>
                                                            <button
                                                                id={"btn_edit_" + entity.identifier}
                                                                className="buttonEdit"
                                                                title="Editar comentário"
                                                                onClick={() => enableEditComment(entity)}
                                                            >
                                                                <FaEdit size={12} color="#fff" />
                                                            </button>
                                                            <button
                                                                id={"btn_remove_" + entity.identifier}
                                                                className="buttonTrash"
                                                                title="Remover comentário"
                                                                onClick={() => actionModalRemove(entity)}
                                                            >
                                                                <FaTrashAlt size={12} color="#fff" />
                                                            </button>
                                                        </div>
                                                    )}
                                                </>
                                            }
                                        />
                                    </Container>
                                );
                            })}
                        {comments.length === 0 && (
                            <div className="flex flex-col items-center">Sem comentários neste processo.</div>
                        )}
                    </>
                )}
            </div>
            {!process?.hideProcess && (
                <div className="flex border-t border-C2 pt-2">
                    <div className="w-full">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                comment: "",
                            }}
                            validationSchema={yup.object().shape({
                                comment: yup.string().required("Campo obrigatório"),
                            })}
                            onSubmit={(values, { setFieldError, setSubmitting, resetForm }) => {
                                setSubmitting(true);

                                requests
                                    .addProcessComment(process.identifier, {
                                        description: values.comment,
                                        hasBeenRead: 0,
                                    })
                                    .then(() => {
                                        loadList();
                                        setSubmitting(false);
                                        resetForm();
                                    })
                                    .finally(() => setSubmitting(false));
                            }}
                        >
                            {({
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                errors,
                                values,
                                touched,
                                handleBlur,
                                setFieldValue,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <FlexDiv>
                                        <Field
                                            name="comment"
                                            component="textarea"
                                            className="rounded-md py-1 px-5 border border-C2 p-1 mb-2 h-10"
                                            rows="4"
                                        />
                                        <span>
                                            <button
                                                className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? (
                                                    <>
                                                        Enviando <ClipLoader size={10} loading={isSubmitting} />
                                                    </>
                                                ) : (
                                                    <>Enviar</>
                                                )}
                                            </button>
                                        </span>
                                    </FlexDiv>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Comments;
