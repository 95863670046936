const isClient = typeof window === "object";

let priorities = isClient ? window.localStorage.getItem("profile-process-priority") : null;
let permissions = isClient ? window.localStorage.getItem("profile-permissions") : null;

const profile = {
    identifier: isClient ? window.localStorage.getItem("profile-identifier") : null,
    name: isClient ? window.localStorage.getItem("profile-name") : null,
    email: isClient ? window.localStorage.getItem("profile-email") : null,
    cellphone: isClient ? window.localStorage.getItem("profile-cellphone") : null,
    phone: isClient ? window.localStorage.getItem("profile-phone") : null,
    role: isClient ? window.localStorage.getItem("profile-role") : null,
    roleHumanReadable: isClient ? window.localStorage.getItem("profile-role-human-readable") : null,
    picture: isClient ? window.localStorage.getItem("profile-picture")?.replace("//profile", "/profile") : null,
    priorities:
        priorities !== null && typeof priorities !== "undefined" && priorities !== "undefined"
            ? JSON.parse(window.localStorage.getItem("profile-process-priority"))
            : [],
    permissions:
        permissions !== null && typeof permissions !== "undefined" && permissions !== "undefined"
            ? JSON.parse(window.localStorage.getItem("profile-permissions"))
            : [],
};

export default profile;
