import React from "react";
import { FaCheck, FaPlane } from "react-icons/fa";
import { RiTruckFill, RiShip2Fill } from "react-icons/ri";
import { AiTwotoneFile } from "react-icons/ai";
import { BsFlagFill } from "react-icons/bs";
import { FaDollarSign } from "react-icons/fa";
import { VscCircleFilled } from "react-icons/vsc";

function StageIcons({ stage = "empty", modality, className }) {
    const Icons = {
        "Pré embarque": <FaCheck className={className} />,
        Trânsito: <ModalityIcons modality={modality} className={className} />,
        Desembaraço: <AiTwotoneFile className={className} />,
        Faturamento: <FaDollarSign className={className} />,
        Transporte: <RiTruckFill className={className} />,
        Entrega: <BsFlagFill className={className} />,
        empty: <span></span>,
    };

    return <>{Icons[stage] || <span>Error</span>}</>;
}
function ModalityIcons({ modality = "", className }) {
    const Icons = {
        "": <VscCircleFilled className={className} />,
        sea: <RiShip2Fill className={className} />,
        air: <FaPlane className={className} />,
        ground: <RiTruckFill className={className} />,
        "": <VscCircleFilled className={className} />,
        marítimo: <RiShip2Fill className={className} />,
        aéreo: <FaPlane className={className} />,
        terrestre: <RiTruckFill className={className} />,
    };
    return <>{Icons[modality.toLowerCase()] || <span>Error</span>}</>;
}

export { ModalityIcons, StageIcons };
