import styled from "styled-components";
import { colors } from "../../../../../assets/colors";

const Container = styled.div`
    justify-self: flex-start;
    div {
        display: grid;
        grid-template-columns: 50px 7fr 3fr 0.1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        align-items: center;
        grid-template-areas: "profileImage title time arr" "stage message message trash";

        img {
            grid-area: profileImage;
            width: 50px;
            height: 50px;
            justify-self: center;
            border: 1px solid ${props => (props.origin ? colors.rosa_oficial : colors.roxo_oficial)};
            border-radius: 90px;
        }
        h6 {
            grid-area: title;
        }
        small {
            grid-area: time;
            justify-self: flex-end;
        }
        .arrowIcon {
            grid-area: arr;
            justify-self: flex-end;
            margin-left: 2px;
        }
        aside {
            grid-area: stage;
            justify-self: center;
            align-self: stretch;
            padding-bottom: 20px;

            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px solid ${props => (props.origin ? colors.rosa_oficial : colors.roxo_oficial)};
                border-radius: 90px;
                width: 25px;
                height: 25px;
                background-color: ${props => (props.origin ? colors.rosa_oficial : colors.roxo_oficial)};
            }

            #line {
                border-left: 1px solid ${props => (props.origin ? colors.rosa_oficial : colors.roxo_oficial)};
                min-height: 30%;
                width: 1px;
                margin: auto;
            }
        }

        span {
            grid-area: message;
            color: ${colors.C6};

            textarea {
                width: 100%;
                height: 35px;
            }
            textarea.txtComments {
                display: none;
            }
        }
        div {
            grid-area: trash;
            display: flex;
            justify-content: center;
            align-items: flex-start;

            button {
                border-radius: 90px;
                justify-self: flex-end;
                align-self: flex-end;
                height: 25px;
                width: 25px;
                margin-bottom: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .buttonCancel {
                background-color: ${colors.C6};
                display: none;
            }
            .buttonEdit {
                background-color: ${colors.C6};
            }
            .buttonSave {
                background-color: ${colors.roxo_oficial};
                display: none;
            }
            .buttonTrash {
                background-color: ${colors.canal_vermelho};
            }
        }
    }
`;

const FlexDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    textarea {
        flex: 4;
    }
    button {
        margin-left: 0.5rem;
        flex: 1;
    }
`;
export { Container, FlexDiv };
