import styled from "styled-components";

export const Heading = styled.div`
    cursor: pointer !important;
    display: flex;
    justify-content: space-between;
    width: 100%;
    transition: background 0.5s;

    :hover {
        background-color: #f1f1f1;
    }
    svg {
        align-self: center;
    }
`;

const Content = styled.div`
    opacity: ${props => (props.open ? "1" : "0")};
    max-height: ${props => (props.open ? "100%" : "0")};
    overflow: hidden;
    padding: ${props => (props.open ? "0" : "4px 0 0 ")};
    transition: all 0.6s;
`;
export default Content;
