import React, { useEffect, useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

import { colors } from "../../../assets/colors";

import Content, { Heading } from "./styles";

function SimpleCollapse({ visible, invisible, open = false }) {
    const [isOpen, setIsOpen] = useState(open);
    const Icon = isOpen ? (
        <IoIosArrowUp className="arrowIcon" color={colors.roxo_oficial} />
    ) : (
        <IoIosArrowDown className="arrowIcon" color={colors.roxo_oficial} />
    );
    useEffect(() => {
        setIsOpen(open);
    }, [open]);
    function openClick() {
        setIsOpen(!isOpen);
    }
    return (
        <>
            <Heading onClick={openClick}>
                {visible}
                {Icon}
            </Heading>
            <Content open={isOpen}>{invisible}</Content>
        </>
    );
}
export default SimpleCollapse;
